@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,400;0,700;1,200;1,400;1,700&display=swap');
@import '_variables';

@import 'loader';
@import 'homepage';
@import 'profile';
@import 'bridge';

html, body {
    margin: 0;
    padding: 0;
    font-family: 'Poppins, IBM Plex Sans'; }

a {
    color: $ultraviolet;
    text-decoration: none; }

.pouch-footer {
    background-color: $nighttime;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 400px;
    line-height: 2;
    a {
        color: $lightning-yellow; } }

.hero-wrapper {
    min-height: 100vh;
    position: relative;
    padding: 24px 12px;
    box-sizing: border-box;
    background-image: linear-gradient(rgba(255,255,255,.5), rgba(255,255,255,1) 100vh);
    .hero-image {
        position: absolute;
        object-fit: cover;
        width: 100%;
        height: 100vh;
        z-index: -1;
        top: 0;
        left: 0; } }

.country-card {
    padding: 16px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    border: 1px solid transparent;
    transition: .2s;
    margin-top: 16px;
    line-height: 24px;
    &.clickable {
        &:hover {
            cursor: pointer;
            opacity: .75; } }
    &.active {
        border: 1px solid $ultraviolet; } }
