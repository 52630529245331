// palette
$ultraviolet: #6922FF; // brand
$nighttime: #160055; // secondary

$azure: #4783EB; // info
$emerald: #1CD787; // success
$bittersweet: #FF6B6B; // error

$bitcoin-orange: #F7931A; // bitcoin
$lightning-yellow: #FFFF55; // lightning

// shades
$white: #FFFFFF;
$light-gray: #E5E5E5;
$med-gray: #929497;
$dark-gray: #393E41;
$black: #000000;


$tablet-breakpoint: 800px;
